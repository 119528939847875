
import Vue, { PropOptions } from "vue";

interface Block {
  Text: String;
  blockImage: {
    data:
      {
        attributes: {
          url: String;
          alternativeText: String;
        };
      }
  };
  Button: {
    Title: String;
    Url: String;
    External: Boolean;
  };
  Smiley: {
    data: {
      attributes: {
        url: String;
        alternativeText: String;
      };
    };
  };
}

export default Vue.extend({
  data() {
    return {
      isFirstItem: false,
    };
  },
  props: {
    content: {
      type: Object,
      required: true,
    } as PropOptions<Block>,
  }
});
